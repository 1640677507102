import React from "react";
import { scale } from "./constants";
import Image from "../Image";

const Logo = ({ logo }) => {
  return (
    <div
      style={{
        height: `${75 * scale}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "1px solid black",
        paddingTop: "20px",
        paddingBottom: "5px",
      }}
    >
      <Image src={logo} height={`${scale * 50}px`} />
    </div>
  );
};

export default Logo;
