import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import Image from "./Image";

const Markdown = ({ text, type }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => (
          <p
            style={{
              marginBlockStart: 0,
              marginBlockEnd: 0,
            }}
            {...props}
          />
        ),
        img: ({ node, ...props }) => (
          <Image isPPT={true} type={type} src={props.src} />
        ),
        table: ({ node, ...props }) => (
          <table
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            {...props}
          />
        ),
        td: ({ node, ...props }) => (
          <td
            style={{
              border: "1px solid #ddd",
              textAlign: "left",
              padding: "5px",
            }}
            {...props}
          />
        ),
        th: ({ node, ...props }) => (
          <th
            style={{
              border: "1px solid #ddd",
              textAlign: "left",
              padding: "5px",
            }}
            {...props}
          />
        ),
      }}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      children={text}
    />
  );
};

export default Markdown;
