const checkOptions = (lang, ques) => {
  if (lang == "hi") {
    for (let i = 0; i < ques.options.length; i++) {
      if (!ques.options[i].hindi_option_text) {
        return true;
      }
    }
  } else if (lang == "en") {
    for (let i = 0; i < ques.options.length; i++) {
      if (!ques.options[i].option_text) {
        return true;
      }
    }
  } else {
    for (let i = 0; i < ques.options.length; i++) {
      if (!ques.options[i].hindi_option_text || !ques.options[i].option_text) {
        return true;
      }
    }
  }
  return false;
};

export const checkQuestions = (questions, isBilingual = false) => {
  if (isBilingual) {
    const all = questions.filter((ques) => ques.lang == "all");
    if (all.length != questions.length) {
      return {
        isError: true,
        msg: `${
          questions.length - all.length
        } questions dont have "all" as their languages`,
      };
    }
  }

  //!lang=="hi"
  const hindi = questions.filter(
    (ques) =>
      ques.lang == "hi" &&
      (!ques.hindi_question || checkOptions(ques.lang, ques))
  );

  //!lang=="en"
  const english = questions.filter(
    (ques) =>
      ques.lang == "en" && (!ques.question || checkOptions(ques.lang, ques))
  );

  //!lang=="all"
  const all = questions.filter(
    (ques) =>
      ques.lang == "all" &&
      (!ques.question || !ques.hindi_question || checkOptions(ques.lang, ques))
  );

  //errors array
  let errors = [...hindi, ...english, ...all];

  return {
    isError: errors.length === 0 ? false : true,
    errors,
  };
};

export const checkSolutions = (questions, isBilingual = false) => {
  if (isBilingual) {
    const all = questions.filter((ques) => ques.lang == "all");
    if (all.length != questions.length) {
      return {
        isError: true,
        msg: `${
          questions.length - all.length
        } questions dont have "all" as their languages`,
      };
    }
  }

  //!lang=="hi"
  const hindi = questions.filter(
    (ques) => ques.lang == "hi" && !ques.solution.hi
  );

  //!lang=="en"
  const english = questions.filter(
    (ques) => ques.lang == "en" && !ques.solution.en
  );

  //!lang=="all"
  const all = questions.filter(
    (ques) => ques.lang == "all" && (!ques.solution.hi || !ques.solution.en)
  );

  //errors array
  let errors = [...hindi, ...english, ...all];

  return {
    isError: errors.length === 0 ? false : true,
    errors,
  };
};
