import React from "react";

const QuesFooter = ({ title, colors }) => {
  return (
    <div
      style={{
        height: "75px",
        borderTop: `1px solid ${colors.footerText}`,
        backgroundColor: colors.footer_bg,
      }}
    >
      <div
        style={{
          height: "75px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "end",
          marginTop: "0px",
          marginBottom: "0px",
          letterSpacing: "0.1rem",
          color: colors.footer_text,
        }}
      >
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default QuesFooter;
