import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";

import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Header from "../components/AnswerKey/Header";

import { A4Size } from "../components/QuestionPdf/constants";

const AnswerKey = ({ hash }) => {
  const [loading, setLoading] = useState(false);
  const [loadingQues, setLoadingQues] = useState(false);

  const [imgs, setImgs] = useState([]);

  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [answers, setAnswers] = useState([]);

  const [logo, setLogo] = useState(null);
  const [title, setTitle] = useState(null);
  const [watermark, setWatermark] = useState(null);

  const fetchAnswers = async () => {
    setLoadingQues(true);

    setFileName(hash + "-answer-key");

    try {
      const res = await fetch(
        `https://students.toppersnotes.com/api/test-answers/${hash}`
      );
      const data = await res.json();

      const toBeInserted = { q_no: "Q.N", answer: "Ans." };

      const answersArray = data.answers;

      let times = Math.ceil(answersArray.length / 20);
      let index = 0;

      while (times > 0) {
        answersArray.splice(index, 0, toBeInserted);
        index += 21;
        times--;
      }
      setLogo(data.coaching.logo);
      setWatermark(data.coaching.watermark);
      setTitle(data.coaching.title);
      console.log("Data: ", data);
      setAnswers(answersArray);
      setLoadingQues(false);
    } catch (error) {
      setAnswers([]);
      setLoadingQues(false);
    }
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById("content"),
      canvas = await html2canvas(element, { allowTaint: true, useCORS: true }),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");
    link.href = data;
    link.download = "downloaded-image.jpg";

    const images = [...imgs, data];
    setImgs([...imgs, data]);
    document.body.appendChild(link);

    document.body.removeChild(link);

    await generatePDF(images);
  };

  const generatePDF = async (images) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_MOCK_PDF_API}/pdf`,
        {
          fileName: fileName ? fileName : "new",
          imgs: images,
          layout: "landscape",
        }
      );
      setUrl(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error is", err);
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, []);

  if (loadingQues) return <h1>Loading Question...</h1>;
  if (!answers.length) return <h1>Invalid Hash</h1>;

  return (
    <div>
      <button
        style={{
          marginBottom: "1rem",
          marginTop: "1rem",
          padding: "0.3rem 0.6rem",
          borderRadius: "12px",
          cursor: "pointer",
        }}
        onClick={async () => {
          setLoading(true);
          await handleDownloadImage();
        }}
      >
        {loading ? "Please wait..." : "Generate Answer Key"}
      </button>

      {url && (
        <div style={{ margin: "1rem" }}>
          <a href={url} target="_blank">
            View PDF
          </a>
          <div style={{ marginTop: "2rem" }}>
            {url}
            <CopyToClipboard text={url}>
              <span
                style={{
                  marginLeft: "1rem",
                  backgroundColor: "#ddd",
                  padding: "4px 10px",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
              >
                Copy to clipboard
              </span>
            </CopyToClipboard>
          </div>
        </div>
      )}

      <div
        id="content"
        style={{
          height: `${A4Size.width}px`,
          width: `${A4Size.height}px`,
          border: "1px solid black",
        }}
      >
        <Header logo={logo} title={title} />
        <div>
          <h1
            style={{
              width: "80%",
              margin: "3% auto",
            }}
          >
            Answer Key
          </h1>
        </div>

        <div
          style={{
            width: "80%",
            margin: "0 auto",
            display: "grid",
            gridTemplateColumns: "repeat(21, 1fr)",
          }}
        >
          {answers.map((ans, i) => (
            <div
              style={{
                border: "1px solid black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
              key={i}
            >
              <span
                style={{
                  width: "100%",
                  padding: "0.25rem",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {ans.q_no}
              </span>
              <span
                style={{
                  borderTop: "1px solid black",
                  width: "100%",
                  padding: "0.25rem",
                  textAlign: "center",
                  fontWeight: ans.answer == "Ans." ? "bold" : "normal",
                }}
              >
                {ans.answer}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnswerKey;
