import React, { useState, useEffect } from "react";
import { fetchImage } from "../utils/fetchImage";
import { getImageSize } from "react-image-size";
import { A4Size as fullSize } from "../components/FullPdf/constants";
import { A4Size as quesSize } from "../components/QuestionPdf/constants";

const Image = ({ src, type, height, isPPT = false }) => {
  let scale = isPPT ? 0.85 : 2;
  let maxWidth = 0.3 * ((isPPT ? quesSize.height : fullSize.width) / 2);

  const [newSrc, setNewSrc] = useState(null);
  const [imgHeight, setImgHeight] = useState(null);
  const [imgWidth, setImgWidth] = useState(null);
  useEffect(() => {
    const getImage = async () => {
      const { width, height } = await getImageSize(src);
      setImgHeight(height * scale);
      setImgWidth(width * scale);

      const url = await fetchImage(src);
      setNewSrc(url);
    };
    getImage();
  }, []);

  return (
    <img
      style={{
        height: height ? height : `${imgHeight}px`,
        width: height ? "none" : `${imgWidth}px`,
        maxWidth: type == "question" ? "none" : `${maxWidth}px`,
      }}
      src={newSrc}
      alt="img"
    />
  );
};

export default Image;
