import React, { useRef, useEffect, useState } from "react";
import { MathJax } from "better-react-mathjax";

import Markdown from "../MarkdownPPT";
import Image from "../Image";

import { A4Size, margin, ratioPage } from "./constants";

const Ques = ({
  ques,
  i,
  setHeight,
  height,
  isQuesPdf = false,
  colors,
  ratio,
}) => {
  const ref = useRef(null);

  const rex = /<img[^>]+src[\s]?="?([^"\s]+)"?\s*\>/g;
  const optRex = /!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g;
  let newQuestion;

  if (ques.data_type !== "question") {
    newQuestion = ques.lang == "hi" ? ques.hindi_text || ques.text : ques.text;
  } else {
    newQuestion =
      ques.lang == "hi" ? ques.hindi_question || ques.question : ques.question;
  }
  let optionsArray = [];

  if (ques.type === "math") {
    if (ques.data_type !== "question") {
      newQuestion =
        ques.lang == "hi"
          ? ques.hindi_text
            ? ques.hindi_text.split(rex)
            : ques.text.split(rex)
          : ques.text.split(rex);
    } else {
      newQuestion =
        ques.lang == "hi"
          ? ques.hindi_question
            ? ques.hindi_question.split(rex)
            : ques.question.split(rex)
          : ques.question.split(rex);
    }

    for (let j = 0; j < newQuestion.length; j++) {
      if (
        !newQuestion[j].startsWith(`'`) &&
        !newQuestion[j].startsWith(`"https`)
      ) {
        newQuestion[j] = `${i == -1 ? "" : `${i}:`} ` + newQuestion[j];
        break;
      }
    }
    for (let j = 0; j < ques.options.length; j++) {
      const text =
        ques.lang == "hi"
          ? ques.options[j].hindi_option_text || ques.options[j].option_text
          : ques.options[j].option_text;
      const opt = text.split(optRex);
      optionsArray.push(opt);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (setHeight) setHeight(i, ref.current.clientHeight);
    }, 500);
  }, []);

  let fontSize = ratio == "3-4" ? "1.4rem" : "1.3rem";

  if (height < (A4Size.width * ratioPage[ratio] - 2 * 75) / 5) {
    fontSize = ratio == "3-4" ? "2.9rem" : "2.3rem";
  } else if (height < (A4Size.width * ratioPage[ratio] - 2 * 75) / 4) {
    fontSize = ratio == "3-4" ? "2.4rem" : "2.1rem";
  } else if (height < (A4Size.width * ratioPage[ratio] - 2 * 75) / 3) {
    fontSize = ratio == "3-4" ? "2rem" : "1.8rem";
  }
  if (!setHeight)
    console.log(
      i,
      ques.linear,
      fontSize,
      height,
      A4Size.width * ratioPage[ratio] - 150
    );

  let width = `${
    (A4Size.width * ratioPage[ratio]) / 2 - 4 * margin * ratioPage[ratio]
  }px`;

  if (isQuesPdf) {
    width = `${
      2.18 *
      ((A4Size.width * ratioPage[ratio]) / 2 - 4 * margin * ratioPage[ratio])
    }px`;
  }

  let quesColor = "#000";
  let optColor = "#000";

  if (isQuesPdf) {
    quesColor = colors.question_color;
    optColor = colors.option_color;
  }

  return (
    <div
      ref={ref}
      style={{
        width: width,
        paddingLeft: `${2 * margin * ratioPage[ratio]}px`,
        paddingRight: `${2 * margin * ratioPage[ratio]}px`,
        paddingTop: `${(margin * ratioPage[ratio]) / 2}px`,
        paddingBottom: `${(margin * ratioPage[ratio]) / 2}px`,
        overflowWrap: "break-word",
        fontSize: isQuesPdf ? fontSize : "1rem",
      }}
      key={i}
    >
      {ques.type === "math" ? (
        <div style={{ fontWeight: "bold", color: quesColor }}>
          {newQuestion.map((newQues, index) => (
            <div key={index}>
              {newQues.startsWith(`'https`) || newQues.startsWith(`"https`) ? (
                <MathJax>
                  <Image
                    isPPT={true}
                    src={newQues.replace(/'|"/g, "")}
                    type="question"
                  />
                </MathJax>
              ) : (
                <MathJax>{newQues}</MathJax>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ fontWeight: "bold", color: quesColor }}>
          <Markdown
            type="question"
            text={`${i == -1 ? "" : `${i}:`} ${newQuestion}`}
          />
        </div>
      )}

      {ques.data_type == "question" && (
        <ol
          style={{
            display: ques.linear ? "block" : "flex",
            flexWrap: ques.linear ? "none" : "wrap",
            color: optColor,
          }}
          type="A"
        >
          {ques.type === "math" ? (
            <>
              {optionsArray.map((opts, i) => (
                <li
                  style={{
                    flex: ques.linear ? "none" : "50%",
                    marginTop: isQuesPdf ? "0.75rem" : "0rem",
                  }}
                  key={i}
                >
                  {opts.map((opt, index) => {
                    return (
                      <div key={index}>
                        {opt &&
                        (opt.startsWith("https") || opt.startsWith("https")) ? (
                          <MathJax>
                            <Image
                              isPPT={true}
                              src={opt.split(" ")[0]}
                              type="option"
                            />
                          </MathJax>
                        ) : (
                          <MathJax>{opt}</MathJax>
                        )}
                      </div>
                    );
                  })}
                </li>
              ))}
            </>
          ) : (
            <>
              {ques.options &&
                ques.options.map((opt, i) => (
                  <li
                    style={{
                      flex: ques.linear ? "none" : "50%",
                      marginTop: isQuesPdf ? "0.75rem" : "0rem",
                    }}
                    key={i}
                  >
                    <>
                      <Markdown
                        type="option"
                        text={
                          ques.lang == "hi"
                            ? opt.hindi_option_text || opt.option_text
                            : opt.option_text
                        }
                      />
                    </>
                  </li>
                ))}
            </>
          )}
        </ol>
      )}
    </div>
  );
};

export default Ques;
