import React from "react";
import Image from "../Image";

const QuesHeader = ({ logo, institute, colors }) => {
  return (
    <div
      style={{
        height: "75px",
        borderBottom: "1px solid black",
        backgroundColor: colors.header_bg,
        color: colors.header_text,
      }}
    >
      <div
        style={{
          width: "90%",
          margin: "0 auto",
          height: "75px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          letterSpacing: "0.1rem",
        }}
      >
        <Image height={"65px"} src={logo} />
        <h1>{institute}</h1>
      </div>
    </div>
  );
};

export default QuesHeader;
