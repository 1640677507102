import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { MathJaxContext } from "better-react-mathjax";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { useLocation } from "react-router-dom";

import QuesHeader from "./QuesHeader";
import QuesFooter from "./QuesFooter";
import Ques from "./QuesSingle";

import { A4Size, ratioPage } from "./constants";

const QuesPDF = ({ heightsArray }) => {
  const [loading, setLoading] = useState(false);
  const [loadingQues, setLoadingQues] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [imgs, setImgs] = useState([]);
  const [ques, setQues] = useState(null);

  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [logo, setLogo] = useState(null);
  const [title, setTitle] = useState(null);
  const [institute, setInstitute] = useState("");
  const [ratio, setRatio] = useState(null);
  const [colors, setColors] = useState({});
  const ref = useRef(null);

  const location = useLocation();

  const fetchQues = async () => {
    setLoadingQues(true);

    const queryParams = new URLSearchParams(location.search);
    const hash = queryParams.get("hash");
    const theme = queryParams.get("theme") || "1";
    setFileName(hash + "-question");
    setRatio(queryParams.get("ratio") || "3-4");

    try {
      const res = await fetch(
        `https://students.toppersnotes.com/api/test-questions?hash=${hash}&theme=${theme}`
      );
      const data = await res.json();

      console.log("Questions in Ques PDF", data.data);

      let quesArr = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].data_type != "question") {
          quesArr.push({ index: -1, ...data.data[i] });
        } else {
          quesArr.push({ index: count, ...data.data[i] });
          count++;
        }
      }

      setQuestions(quesArr);

      setLogo(data.logo);
      setTitle(data.title);
      setInstitute(data["Coaching name"]);
      setColors(data.theme);
      if (data.data.length) setQues(quesArr[0]);
      setLoadingQues(false);
    } catch (error) {
      setQuestions([]);
      setLoadingQues(false);
    }
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById("content"),
      canvas = await html2canvas(element, { allowTaint: true, useCORS: true }),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");
    link.href = data;
    link.download = "downloaded-image.jpg";

    const images = [...imgs, data];
    setImgs([...imgs, data]);
    document.body.appendChild(link);
    // link.click();
    document.body.removeChild(link);
    let i = currentPage;

    if (i < questions.length) {
      setQues(questions[i]);

      setCurrentPage((c) => c + 1);
      setTimeout(() => {
        ref.current.click();
      }, 1000);
    } else {
      await generatePDF(images);

      setCurrentPage("ended");
    }
  };

  const generatePDF = async (images) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_MOCK_PDF_API}/pdf`,
        {
          fileName: fileName ? fileName : "new",
          imgs: images,
          layout: "portrait",
          type: "ppt",
          ratio: ratio,
        }
      );
      setUrl(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error is", err);
    }
  };

  useEffect(() => {
    fetchQues();
  }, []);

  if (loadingQues) return <h1>Loading Question...</h1>;
  if (!questions.length) return <h1>Invalid Hash</h1>;

  return (
    <MathJaxContext renderMode="post">
      <div>
        <button
          style={{
            marginBottom: "1rem",
            marginTop: "1rem",
            padding: "0.3rem 0.6rem",
            borderRadius: "12px",
            cursor: "pointer",
          }}
          ref={ref}
          onClick={async () => {
            setLoading(true);
            await handleDownloadImage();
          }}
        >
          {loading ? "Please wait..." : "Generate Question PDF"}
        </button>

        {url && (
          <div style={{ margin: "1rem" }}>
            <a href={url} target="_blank">
              View PDF
            </a>
            <div style={{ marginTop: "2rem" }}>
              {url}
              <CopyToClipboard text={url}>
                <span
                  style={{
                    marginLeft: "1rem",
                    backgroundColor: "#ddd",
                    padding: "4px 10px",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  Copy to clipboard
                </span>
              </CopyToClipboard>
            </div>
          </div>
        )}

        <div
          id="content"
          style={{
            height: `${A4Size.width * ratioPage[ratio]}px`,
            width: `${A4Size.height}px`,
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: colors.page_bg,
          }}
        >
          <div>
            <QuesHeader colors={colors} logo={logo} institute={institute} />

            <div
              style={{
                marginLeft: ratio == "3-4" ? "23%" : "30%",
                marginTop: "2%",
              }}
            >
              {ques && (
                <Ques
                  ratio={ratio}
                  isLeftCol={true}
                  i={ques.index}
                  ques={ques}
                  key={ques.index}
                  isQuesPdf={true}
                  colors={colors}
                  height={heightsArray[currentPage - 1]}
                />
              )}
            </div>
          </div>
          <div style={{}}>
            <QuesFooter colors={colors} title={title} />
          </div>
        </div>
      </div>
    </MathJaxContext>
  );
};

export default QuesPDF;
