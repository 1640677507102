export const ratioPage = {
  "3-4": 0.75,
  "9-16": 0.5625,
};

export const A4Size = {
  height: 960 * 1.5,
  width: 960 * 1.5,
};
export const margin = 16;
