import React, { useEffect, useState, useRef } from "react";

import axios from "axios";

import Ques from "../components/QuestionPdf/QuesSingle";
import { checkQuestions } from "../utils/checkQuestions";
import { A4Size } from "../components/QuestionPdf/constants";
import QuestionPdf from "../components/QuestionPdf/Main";

const FullPdf = ({ hash }) => {
  const [loadingQues, setLoadingQues] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [checkResult, setCheckResult] = useState(null);
  const [questionPdf, setQuestionPdf] = useState(false);
  const [heightsArrayState, setHeightsArrayState] = useState([]);
  const [disabled, setDisabled] = useState(true);

  let heightsArray = [];

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const fetchQues = async () => {
    setLoadingQues(true);

    try {
      const res = await axios.get(
        `https://students.toppersnotes.com/api/test-questions?hash=${hash}`
      );
      const data = res.data;
      let quesArr = [];
      let count = 1;
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].data_type != "question") {
          quesArr.push({ index: -1, ...data.data[i] });
        } else {
          quesArr.push({ index: count, ...data.data[i] });
          count++;
        }
      }
      const result = checkQuestions(
        quesArr.filter((ques) => ques.index !== -1)
      );
      console.log("Result", result);
      setCheckResult(result);

      console.log("Questions Full PDF", quesArr);
      setQuestions(quesArr);

      setLoadingQues(false);
    } catch (error) {
      setQuestions([]);
      setLoadingQues(false);
    }
  };

  const setHeight = (i, val) => {
    heightsArray[i] = val;
    setHeightsArrayState((current) => {
      current[i] = val;
      return current;
    });
  };

  useEffect(() => {
    fetchQues();
  }, []);

  if (loadingQues) return <h1>Loading Question...</h1>;
  if (!questions.length) return <h1>Invalid Hash</h1>;

  if (questionPdf) return <QuestionPdf heightsArray={heightsArrayState} />;

  if (checkResult && checkResult.isError)
    return (
      <div>
        <h2>Following errors occurred:</h2>
        <ol>
          {checkResult.errors.map((err) => (
            <li key={err.id} style={{ padding: "0.5rem" }}>
              Question: {err.index} - id: {err.id} - Lang: {err.lang} -{" "}
              {err.lang == "hi"
                ? "Hindi text is missing in questions/options!"
                : err.lang == "en"
                ? "English text is missing in questions/options!"
                : "Both English and Hindi text should be present for lang all!"}
            </li>
          ))}
        </ol>
      </div>
    );

  return (
    <div>
      {questions.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "1rem 0",
            }}
          >
            <button
              disabled={disabled}
              style={{
                padding: "0.3rem 0.6rem",
                borderRadius: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                // setHeightsArrayState(heightsArray);
                setQuestionPdf(true);
              }}
            >
              {disabled ? "Please wait ..." : "Generate PPT"}
            </button>
          </div>
          <div
            style={{
              width: `${A4Size.width / 2}px`,
              border: "1px solid black",
            }}
          >
            {questions.map((ques, i) => (
              <Ques
                isLeftCol={true}
                key={ques + i}
                ques={ques}
                i={i}
                setHeight={setHeight}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FullPdf;
