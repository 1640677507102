import React from "react";
import { scale } from "./constants";
import Image from "../Image";

const Header = ({ title, logo }) => {
  return (
    <div
      style={{
        height: `${75 * scale}px`,
        borderBottom: "1px solid black",
        // color: "white",
        // background: "black",
      }}
    >
      <div
        style={{
          width: "90%",
          height: `${65 * scale}px`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginTop: `${10 * scale}px`,
          marginBottom: "0px",
          margin: "0 auto",
        }}
      >
        <span style={{ fontSize: `${1.7 * scale}rem` }}>{title}</span>

        <Image height={`${scale * 50}px`} src={logo} />
      </div>
    </div>
  );
};

export default Header;
