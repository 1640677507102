import axios from "axios";

export const fetchImage = async (imgUrl) => {
  try {
    const config = {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };
    const res = await axios.get(imgUrl, config);
    const blob = res.data;
    const dataUrl = URL.createObjectURL(blob);
    return dataUrl;
  } catch (error) {
    console.log("Image fetch error for", imgUrl, "", error);
    return null;
  }
};
