import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Watermark from "@uiw/react-watermark";
import Logo from "../components/FullPdf/Logo";
import Header from "../components/FullPdf/Header";

import Solution from "../components/BilingualSolutions/Solution";
import { A4Size, scale } from "../components/FullPdf/constants";
import { checkSolutions } from "../utils/checkQuestions";

const Solutions = ({ hash }) => {
  const [column1, setCol1] = useState(null);
  const [column2, setCol2] = useState(null);
  const [col1Pic, setCol1Pic] = useState(null);
  const [col2Pic, setCol2Pic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingQues, setLoadingQues] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [imgs, setImgs] = useState([]);
  const [isColumnSeparated, setIsColumnSeparated] = useState(false);
  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState(null);
  const [logo, setLogo] = useState(null);
  const [watermark, setWatermark] = useState(null);
  const [isHindi, setIsHindi] = useState(true);
  const [checkResult, setCheckResult] = useState(null);

  const ref = useRef(null);

  const pageHeight = A4Size.height - 75 * scale;

  let heightsArray = [];

  const fetchQues = async () => {
    setLoadingQues(true);

    setFileName(hash + "-bilingual-solutions");

    try {
      const res = await axios.get(
        `https://students.toppersnotes.com/api/test-solutions/${hash}`
      );
      const data = res.data.solutions;
      const result = checkSolutions(data, true);
      console.log("Result", result);
      setCheckResult(result);

      console.log("Solutions", res.data);
      setQuestions(data);
      setTitle(res.data.coaching.title);
      setLogo(res.data.coaching.logo);
      setWatermark(res.data.coaching.watermark);

      setLoadingQues(false);
    } catch (error) {
      setQuestions([]);
      setLoadingQues(false);
    }
  };

  const setHeight = (i, val) => {
    heightsArray[i] = val;
  };

  const separteColumnWise = () => {
    // let sum = margin / 2;
    let sum = 0;
    const col1 = [];
    const col2 = [];
    let currentCol = 1;
    let topMarginCol1 = false;
    let topMarginCol2 = false;
    let left = 0;
    let right = 0;
    const pageDataArray = [];
    for (let i = 0; i < questions.length; i++) {
      sum += heightsArray[i];

      if (sum > pageHeight) {
        if (currentCol === 1) {
          col1.push(pageHeight - sum + heightsArray[i]);
          left++;
          topMarginCol1 = true;
        } else {
          col2.push(pageHeight - sum + heightsArray[i]);
          right++;
          topMarginCol2 = true;
          let ele;
          if (pageDataArray.length === 0) {
            ele = [left, right];
          } else {
            ele = [
              left + pageDataArray[pageDataArray.length - 1][0],
              right + pageDataArray[pageDataArray.length - 1][1],
            ];
          }
          pageDataArray.push(ele);
          left = 0;
          right = 0;
        }
        currentCol = currentCol === 1 ? 2 : 1;
        // sum = margin / 2 + heightsArray[i];
        sum = heightsArray[i];
      }
      if (currentCol === 1) {
        col1.push({
          ...questions[i],
          topMargin: topMarginCol1,
          height: heightsArray[i],
        });
        if (topMarginCol1) {
          topMarginCol1 = false;
        }
        left++;
      } else {
        col2.push({
          ...questions[i],
          topMargin: topMarginCol2,
          height: heightsArray[i],
        });
        if (topMarginCol2) {
          topMarginCol2 = false;
        }
        right++;
      }
      if (i === questions.length - 1) {
        let ele;
        if (pageDataArray.length === 0) {
          ele = [left, right];
        } else {
          ele = [
            left + pageDataArray[pageDataArray.length - 1][0],
            right + pageDataArray[pageDataArray.length - 1][1],
          ];
        }
        pageDataArray.push(ele);
      }
    }

    setCol1(col1);
    setCol2(col2);

    setCol1Pic(col1.slice(0, pageDataArray[0][0]));
    setCol2Pic(col2.slice(0, pageDataArray[0][1]));

    setIsColumnSeparated(true);

    setPageData(pageDataArray);
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById("content"),
      canvas = await html2canvas(element, { allowTaint: true, useCORS: true }),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");
    link.href = data;
    link.download = "downloaded-image.jpg";

    const images = [...imgs, data];
    setImgs([...imgs, data]);
    document.body.appendChild(link);
    // link.click();
    document.body.removeChild(link);
    let i = currentPage;

    if (i < pageData.length) {
      if (i == 0) {
        setCol1Pic(column1.slice(0, pageData[0][0]));
        setCol2Pic(column2.slice(0, pageData[0][1]));
      } else {
        setCol1Pic(column1.slice(pageData[i - 1][0], pageData[i][0]));
        setCol2Pic(column2.slice(pageData[i - 1][1], pageData[i][1]));
      }

      if (isHindi || i == 0) {
        setCurrentPage((c) => c + 1);
      }
      setIsHindi((a) => !a);
      setTimeout(() => {
        ref.current.click();
      }, 1000);
    } else {
      await generatePDF(images);
      setCurrentPage("ended");
    }
  };

  const generatePDF = async (images) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_MOCK_PDF_API}/pdf`,
        {
          fileName: fileName ? fileName : "new",
          imgs: images,
        }
      );
      setUrl(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error is", err);
    }
  };

  useEffect(() => {
    fetchQues();
  }, []);

  if (loadingQues) return <h1>Loading Question...</h1>;
  if (!questions.length) return <h1>Invalid Hash</h1>;
  // if (checkResult && checkResult.isError)
  //   return (
  //     <div>
  //       <h2>Following errors occurred:</h2>
  //       <ol>
  //         {checkResult.msg ? (
  //           checkResult.msg
  //         ) : (
  //           <>
  //             {checkResult.errors.map((err) => (
  //               <li key={err.id} style={{ padding: "0.5rem" }}>
  //                 Question: {err.q_no} - Lang: {err.lang} -{" "}
  //                 {err.lang == "hi"
  //                   ? "Hindi text is missing in Solutions"
  //                   : err.lang == "en"
  //                   ? "English text is missing in Solutions"
  //                   : "Both English and Hindi text should be present for lang all!"}
  //               </li>
  //             ))}
  //           </>
  //         )}
  //       </ol>
  //     </div>
  //   );

  return (
    <div>
      {isColumnSeparated ? (
        <>
          <button
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              padding: "0.3rem 0.6rem",
              borderRadius: "12px",
              cursor: "pointer",
            }}
            ref={ref}
            onClick={async () => {
              setLoading(true);
              await handleDownloadImage();
            }}
          >
            {loading ? "Please wait..." : "Generate PDF"}
          </button>

          {url && (
            <div style={{ margin: "1rem" }}>
              <a href={url} target="_blank">
                View PDF
              </a>
              <div style={{ marginTop: "2rem" }}>
                {url}
                <CopyToClipboard text={url}>
                  <span
                    style={{
                      marginLeft: "1rem",
                      backgroundColor: "#ddd",
                      padding: "4px 10px",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                  >
                    Copy to clipboard
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          )}

          <div
            id="content"
            style={{
              width: `${A4Size.width}px`,
              height: `${A4Size.height}px`,
            }}
          >
            <Watermark
              gapY={A4Size.width + 500 * scale}
              gapX={A4Size.height / 5}
              height={(scale / 1) * 55 * 4}
              width={(scale / 1) * 225 * 3}
              image={watermark}
            >
              {currentPage == 0 ? (
                <Header title={title} logo={logo} />
              ) : (
                <Logo logo={logo} />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                }}
              >
                <div
                  id="col1"
                  style={{
                    width: `${A4Size.width / 2}px`,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {col1Pic.map((ques, i) =>
                    typeof ques == "number" ? (
                      <div
                        key={i}
                        style={{
                          height: `${ques}px`,
                        }}
                      ></div>
                    ) : (
                      <div
                        key={i}
                        style={{
                          height: `${ques.height}px`,
                          overflow: "hidden",
                        }}
                      >
                        <Solution
                          isHindi={isHindi}
                          i={ques.index}
                          ques={ques}
                          key={i}
                        />
                      </div>
                    )
                  )}
                </div>

                <div
                  id="col2"
                  style={{
                    width: `${A4Size.width / 2}px`,
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "1px solid gray",
                  }}
                >
                  {col2Pic.map((ques, i) =>
                    typeof ques == "number" ? (
                      <div
                        key={i}
                        style={{
                          height: `${ques}px`,
                        }}
                      ></div>
                    ) : (
                      <div
                        key={i}
                        style={{
                          height: `${ques.height}px`,
                          overflow: "hidden",
                        }}
                      >
                        <Solution
                          isHindi={isHindi}
                          i={ques.index}
                          ques={ques}
                          key={i}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Watermark>
          </div>
        </>
      ) : (
        <div>
          {questions.length > 0 && (
            <>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5rem" }}
              >
                <button
                  style={{
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    padding: "0.3rem 0.6rem",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    separteColumnWise();
                  }}
                >
                  Generate Full PDF
                </button>
              </div>
              <div
                style={{
                  width: `${A4Size.width / 2}px`,
                  border: "1px solid black",
                }}
              >
                {questions.map((ques, i) => (
                  <Solution
                    key={ques + i}
                    ques={ques}
                    i={i}
                    setHeight={setHeight}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Solutions;
