import React, { useRef, useEffect } from "react";
import { MathJax } from "better-react-mathjax";
import Markdown from "../Markdown";
import { A4Size, margin, scale } from "../FullPdf/constants";

const Ques = ({ ques, i, setHeight }) => {
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (setHeight) setHeight(i, ref.current.clientHeight);
    }, 2000);
  }, []);

  let width = `${A4Size.width / 2 - 4 * margin}px`;

  let fontSize = `${scale * 1}rem`;

  const explanation =
    ques.lang == "en"
      ? ques.solution.en
        ? ques.solution.en
        : ques.solution.hi
      : ques.solution.hi
      ? ques.solution.hi
      : ques.solution.en;

  return (
    <div
      ref={ref}
      style={{
        width: width,
        paddingLeft: `${2 * margin}px`,
        paddingRight: `${2 * margin}px`,
        paddingTop: `${margin / 2}px`,
        paddingBottom: `${margin / 2}px`,
        overflowWrap: "break-word",
        fontSize: fontSize,
      }}
      key={i}
    >
      <div style={{ fontWeight: "bold" }}>
        <Markdown type="question" text={`${ques.q_no}. ${ques.answer}`} />
      </div>
      <div>
        {ques.type === "math" ? (
          <MathJax>{explanation}</MathJax>
        ) : (
          <Markdown type="option" text={explanation} />
        )}
      </div>
    </div>
  );
};

export default Ques;
