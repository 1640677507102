import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import FullPdf from "./pages/FullPdf";
import QuestionPdf from "./pages/QuestionPdf";
import AnswerKey from "./pages/AnswerKey";
import Solutions from "./pages/Solutions";
import Bilingual from "./pages/Bilingual";
import BilingualSolutions from "./pages/BilingualSolutions";

import { MathJaxContext } from "better-react-mathjax";

const App = () => {
  //obtaining the hash
  const location = useLocation();
  const [hash, setHash] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setHash(queryParams.get("hash"));
  }, []);

  if (!hash) return <h1>Invalid Hash!</h1>;

  return (
    <MathJaxContext renderMode="post">
      <Routes>
        <Route path="/" element={<FullPdf hash={hash} />} />
        <Route path="/question-pdf" element={<QuestionPdf hash={hash} />} />
        <Route path="/answer-key" element={<AnswerKey hash={hash} />} />
        <Route path="/solutions" element={<Solutions hash={hash} />} />
        <Route path="/bilingual" element={<Bilingual hash={hash} />} />
        <Route
          path="/bilingual-solutions"
          element={<BilingualSolutions hash={hash} />}
        />
      </Routes>
    </MathJaxContext>
  );
};

export default App;
