import React, { useRef, useEffect, useState } from "react";
import { MathJax } from "better-react-mathjax";
import { useNavigate, useLocation } from "react-router-dom";
import Markdown from "../Markdown";

import { A4Size, margin, scale } from "./constants";
import Image from "../Image";

const Ques = ({ ques, i, setHeight, height, colors }) => {
  const ref = useRef(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  if (lang) ques.lang = lang;

  const rex = /<img[^>]+src[\s]?="?([^"\s]+)"?\s*\>/g;
  const optRex = /!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g;
  let newQuestion;

  if (ques.data_type !== "question") {
    newQuestion =
      ques.lang == "hi"
        ? ques.hindi_text || ques.text
        : ques.text || ques.hindi_text;
  } else {
    newQuestion =
      ques.lang == "hi"
        ? ques.hindi_question || ques.question
        : ques.question || ques.hindi_question;
  }
  let optionsArray = [];

  if (ques.type === "math") {
    if (ques.data_type !== "question") {
      newQuestion =
        ques.lang == "hi"
          ? ques.hindi_text
            ? ques.hindi_text.split(rex)
            : ques.text.split(rex)
          : ques.text
          ? ques.text.split(rex)
          : ques.hindi_text.split(rex);
    } else {
      newQuestion =
        ques.lang == "hi"
          ? ques.hindi_question
            ? ques.hindi_question.split(rex)
            : ques.question.split(rex)
          : ques.question
          ? ques.question.split(rex)
          : ques.hindi_question.split(rex);
    }

    for (let j = 0; j < newQuestion.length; j++) {
      if (
        !newQuestion[j].startsWith(`'`) &&
        !newQuestion[j].startsWith(`"https`)
      ) {
        newQuestion[j] = `${i == -1 ? "" : `${i}:`} ` + newQuestion[j];
        break;
      }
    }
    for (let j = 0; j < ques.options.length; j++) {
      const text =
        ques.lang == "hi"
          ? ques.options[j].hindi_option_text || ques.options[j].option_text
          : ques.options[j].option_text || ques.options[j].hindi_option_text;
      const opt = text.split(optRex);
      optionsArray.push(opt);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (setHeight) setHeight(i, ref.current.clientHeight);
    }, 2000);
  }, []);

  let width = `${A4Size.width / 2 - 4 * margin}px`;

  let quesColor = "#000";
  let optColor = "#000";

  let fontSize = `${scale * 1}rem`;

  return (
    <div
      ref={ref}
      style={{
        width: width,
        paddingLeft: `${2 * margin}px`,
        paddingRight: `${2 * margin}px`,
        paddingTop: `${margin / 2}px`,
        paddingBottom: `${margin / 2}px`,
        overflowWrap: "break-word",
        fontSize: fontSize,
      }}
      key={i}
    >
      {ques.type === "math" ? (
        <div style={{ fontWeight: "bold", color: quesColor }}>
          {newQuestion.map((newQues, index) => (
            <div key={index}>
              {newQues.startsWith(`'https`) || newQues.startsWith(`"https`) ? (
                <MathJax>
                  <Image src={newQues.replace(/'|"/g, "")} type="question" />
                </MathJax>
              ) : (
                <MathJax>{newQues}</MathJax>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ fontWeight: "bold", color: quesColor }}>
          <Markdown
            type="question"
            text={`${i == -1 ? "" : `${i}:`} ${newQuestion}`}
          />
        </div>
      )}

      {ques.data_type == "question" && (
        <ol
          style={{
            display: ques.linear ? "block" : "flex",
            flexWrap: ques.linear ? "none" : "wrap",
            color: optColor,
            paddingLeft: `${scale * 2}rem`,
          }}
          type="A"
        >
          {ques.type === "math" ? (
            <>
              {optionsArray.map((opts, i) => (
                <li
                  style={{
                    flex: ques.linear ? "none" : "50%",
                    marginTop: "0rem",
                  }}
                  key={i}
                >
                  {opts.map((opt, index) => {
                    return (
                      <div key={index}>
                        {opt &&
                        (opt.startsWith("https") || opt.startsWith("https")) ? (
                          <MathJax>
                            <Image src={opt.split(" ")[0]} type="option" />
                          </MathJax>
                        ) : (
                          <MathJax>{opt}</MathJax>
                        )}
                      </div>
                    );
                  })}
                </li>
              ))}
            </>
          ) : (
            <>
              {ques.options &&
                ques.options.map((opt, i) => (
                  <li
                    style={{
                      flex: ques.linear ? "none" : "50%",
                      marginTop: "0rem",
                    }}
                    key={i}
                  >
                    <>
                      <Markdown
                        type="option"
                        text={
                          ques.lang == "hi"
                            ? opt.hindi_option_text || opt.option_text
                            : opt.option_text || opt.hindi_option_text
                        }
                      />
                    </>
                  </li>
                ))}
            </>
          )}
        </ol>
      )}
    </div>
  );
};

export default Ques;
